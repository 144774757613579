import React, { useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import afaslogo from "../../images/afas.png";
import axios from "axios";

const Afas = () => {
  const [medewerkerValue, setMedewerkerValue] = useState("");
  const [naam, setNaam] = useState("");
  const [upn, setUpn] = useState("");
  const [werkgever, setWerkgever] = useState("");
  const [emailadres, setEmailadres] = useState("");
  const [apiData, setApiData] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [dataFound, setDataFound] = useState(true);

  const navigate = useNavigate();
  const { logout } = UserAuth();

  const handleLogOff = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleInputChange = (event) => {
    setMedewerkerValue(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      setNaam("");
      setUpn("");
      setEmailadres("");
      setWerkgever("");

      const apiUrl =
        "https://93042.rest.afas.online/ProfitRestServices/connectors/Ecare_AFAS_Medewerkers_Gebruikers?";
      const filter = {
        Filters: {
          Filter: [
            {
              "@FilterId": "Filter 1",
              Field: [
                {
                  "@FieldId": "Medewerker",
                  "@OperatorType": 1,
                  "#text": medewerkerValue,
                },
              ],
            },
          ],
        },
      };
      const authToken =
        "AfasToken PHRva2VuPjx2ZXJzaW9uPjE8L3ZlcnNpb24+PGRhdGE+QkM5QzVENkVBQTUxNERGMkJFQkQzOENFRjgzNEZCOEU1NzI5QkU0ODI2MDU0NTgxQjFDOTlDODE4NzZEMjVCQjwvZGF0YT48L3Rva2VuPg==";

      const headers = {
        Authorization: `${authToken}`,
      };

      const response = await axios.get(apiUrl, {
        params: {
          filterjson: JSON.stringify(filter),
        },
        headers: headers,
      });

      const responseData = response.data.rows[0];
      if (responseData) {
        setApiData(responseData);
        setDataFound(true);
        // Update the state variables with the corresponding values
        setNaam(responseData.Naam);
        setUpn(responseData.UPN);
        setEmailadres(responseData["Gebruiker_e-mail"]);
        setWerkgever(responseData.Naam_werkgever);

        // Add similar lines for other fields
      } else {
        setNaam("Niet gevonden");
        setUpn("");
        setEmailadres("");
        setWerkgever("");
      }

      console.log(responseData);
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };

  return (
    <div className="max-w-[500px] mx-auto my-16 p-4">
      <form
        onSubmit={handleFormSubmit}
        className="bg-indigo-100 shadow-xl rounded px-8 pt-6 pb-8 mb-4"
      >
        <img
          src={afaslogo}
          alt="AFAS"
          style={{ height: "40px", width: "auto" }}
        ></img>
        <div className="mb-4">
          <label className="ml-1 font-medium">Personeelsnummer</label>
          <input
            value={medewerkerValue}
            autoComplete="off"
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="medewerkerValue"
            type="text"
          />
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-700 shadow-xl text-white font-bold py-2 px-4 rounded"
          type="submit"
        >
          Zoeken
        </button>
      </form>
      <form className="bg-indigo-100 shadow-xl rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="ml-1 font-medium">Naam</label>
          <input
            readOnly={readOnly}
            value={naam}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="Naam"
            type="text"
          />
        </div>
        <div className="mb-4">
          <label className="ml-1 font-medium">UPN</label>
          <input
            readOnly={readOnly}
            value={upn}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="UPN"
            type="text"
          />
        </div>
        <div className="mb-4">
          <label className="ml-1 font-medium">E-mailadres</label>
          <input
            readOnly={readOnly}
            value={emailadres}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="e-mail"
            type="text"
          />
        </div>
        <div className="mb-3">
          <label className="ml-1 font-medium">Werkgever</label>
          <input
            readOnly={readOnly}
            value={werkgever}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="Werkgever"
            type="text"
          />
        </div>
      </form>
      <button
        onClick={handleLogOff}
        className="bg-blue-500 hover:bg-blue-700 shadow-xl float-right text-white font-bold py-2 px-4 rounded"
        type="submit"
      >
        Uitloggen
      </button>
    </div>
  );
};

export default Afas;
