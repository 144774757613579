import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import afaslogo from "../../images/afas.png";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [authenticationError, setAuthenticationError] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const navigate = useNavigate();
  const { signin, resetPassword } = UserAuth(); //

  const handleSignIn = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    setAuthenticationError("");

    if (!email) {
      setEmailError("Geen e-mailadres ingevuld");
      return;
    }

    if (!password) {
      setPasswordError("Geen wachtwoord ingevuld");
      return;
    }

    try {
      await signin(email, password);
      navigate("/afas");
    } catch (error) {
      console.error("Authentication error:", error.message);
      setAuthenticationError(error.code);
      if (error.code === "auth/invalid-credential") {
        setAuthenticationError("Ongeldig e-mailadres of wachtwoord");
      } else if (error.code === "auth/too-many-requests") {
        setAuthenticationError(
          "Account is tijdelijk geblokkeerd, probeer het later nog eens"
        );
      } else {
        // Handle other authentication errors as needed
        setAuthenticationError("Inloggen mislukt, probeer het later nog eens");
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(email);
      setResetMessage(`Wachtwoordresetmail is verstuurd naar ${email}`);
      console.log("Password reset email sent successfully!");
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      setResetMessage(
        "Er is een fout opgetreden bij het versturen van de wachtwoordresetmail"
      );
    }
  };

  return (
    <div className="max-w-[500px] mx-auto my-16 p-4">
      <form
        className="bg-indigo-100  shadow-xl rounded px-8 pt-6 pb-8 mb-4 relative" // Add relative positioning
        onSubmit={handleSignIn}
      >
        <img
          src={afaslogo}
          alt="AFAS"
          style={{ height: "40px", width: "auto" }}
        ></img>
        <div className="mb-4">
          <label className="ml-1 font-medium">E-mailadres</label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              emailError ? "border-red-500" : ""
            }`}
            id="username"
            type="email"
            autoComplete="off"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
              setAuthenticationError("");
            }}
          />
          {emailError && (
            <p className="text-red-500 text-sm absolute bottom-[-25px] left-0">
              {emailError}
            </p>
          )}
        </div>
        <div className="mb-6">
          <label className="py-2 ml-1 font-medium">Wachtwoord</label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              passwordError ? "border-red-500" : ""
            }`}
            id="password"
            type="password"
            autoComplete="off"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError("");
              setAuthenticationError("");
            }}
          />
          {passwordError && (
            <p className="text-red-500 text-sm absolute bottom-[-25px] left-0">
              {passwordError}
            </p>
          )}
        </div>
        {authenticationError && (
          <p className="text-red-500 text-sm absolute bottom-[-25px] left-0">
            {authenticationError}
          </p>
        )}
        <button
          className="bg-blue-500 hover:bg-blue-700 shadow-xl text-white font-bold py-2 px-4 rounded"
          type="submit"
        >
          Inloggen
        </button>

        <button
          onClick={handleResetPassword}
          className="bg-blue-500 hover:bg-blue-700 shadow-xl text-white font-bold ml-2 py-2 px-4 rounded"
        >
          Wachtwoord vergeten?
        </button>
        {resetMessage && (
          <p className="text-green-500 text-sm absolute bottom-[-25px] left-0">
            {resetMessage}
          </p>
        )}
      </form>
    </div>
  );
};

export default SignIn;
