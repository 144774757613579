// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Afas from "./components/afas/afas";
import SignIn from "./components/auth/Signin";

import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/auth/ProtectedRoute";

const App = () => {
  
  return (
    <AuthContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route
            path="/afas"
            element={
              <ProtectedRoute>
                <Afas />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthContextProvider>
  );
};

export default App;
