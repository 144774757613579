// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCyoPguRnk8DFPr0ZYxprWOyaseADbQ_2s",
  authDomain: "afas-for-sd.firebaseapp.com",
  projectId: "afas-for-sd",
  storageBucket: "afas-for-sd.appspot.com",
  messagingSenderId: "909686028347",
  appId: "1:909686028347:web:d2a2220350cdb6a9dcc41e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app

